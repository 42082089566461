<template lang="pug">
.shipping-address(v-if="!loading")
  h1 Endereço de entrega

  .shipping-rate-notice
    | Alterações no endereço podem acarretar em alterações no prazo e frete.

  AddressForm(
    ref="form",
    :data="shippingAddress",
    @update:postalCode="calculateShippingRate")

  .shipping(v-if="primarySubscription != null")
    h2 Frete e estimativa de entrega

    table
      tr
        td Frete
        td
          Loading(v-if="loadingShippingRate")
          span(v-else-if="shippingCost == 0") Grátis
          span(v-else) R$ {{ centsToString(shippingCost) }}

      tr
        td Estimativa de entrega
        td
          Loading(v-if="loadingShippingRate")
          span(v-else-if="shippingDays == 1") 1 dia útil
          span(v-else) {{ shippingDays }} dias úteis

  .actions
    Button(
      label="Salvar",
      to="",
      @click.prevent="save",
      :loading="saving",
      size="large",
      rounded
    )

.edit-shipping-address-loading(v-else)
  Loading
</template>
<script setup lang="ts">
import type { FetchError } from 'ofetch'
import type { AddressInfo } from '~/components/address/AddressForm.vue'
import type { PrimarySubscription } from '~/services/subscription/subscriptionService'
import type { ShippingAddress } from '~/services/shippingAddress/shippingAddressService'

import CustomerService from '~/services/customer/customerService'
import ShippingAddressService from '~/services/shippingAddress/shippingAddressService'

import AddressForm from '~/components/address/AddressForm.vue'

import { useAuth } from '~/store/auth'

const auth = useAuth()
const notifications = useNotifications()

const route = useRoute()
const router = useRouter()
const form = ref<InstanceType<typeof AddressForm> | null>()
const primarySubscription = ref<PrimarySubscription | null>(null)
const shippingAddress = ref<ShippingAddress>()
const initialPostalCode = ref<string>('')
const shippingCost = ref<number>(0)
const shippingDays = ref<number>(0)
const loading = ref<boolean>(false)
const loadingShippingRate = ref<boolean>(false)
const saving = ref<boolean>(false)
const returnTo = ref<string | null>(null)

useHead({
  title: 'Endereço de entrega'
})

definePageMeta({
  requiresAuth: true,
  breadcrumb: [
    {
      title: 'Minha conta',
      link: { name: 'dashboard' },
    },
    {
      title: 'Endereço de entrega',
      link: { name: 'edit-shipping-address' },
    },
  ],
})

const calculateShippingRate = async (addressInfo: AddressInfo) => {
  try {
    loadingShippingRate.value = true

    const response = await ShippingAddressService.calculateShippingRate({
      customerEmail: auth.userEmail ?? "",
      state: addressInfo.state,
      postalCode: addressInfo.postalCode
    })

    if (addressInfo.postalCode == initialPostalCode.value && primarySubscription.value != null) {
      shippingCost.value = primarySubscription.value.shippingCost
    } else {
      shippingCost.value = response.price
    }
    shippingDays.value = response.days
  } catch (e) {
    captureException(new Error('Failed to calculate shipping rate', { cause: e }))

    notifications.error("Não foi possível calcular o valor do frete. Por favor, tente novamente mais tarde")
  } finally {
    loadingShippingRate.value = false
  }
}

const save = async () => {
  const f = form.value
  const sa = shippingAddress.value

  if (sa && f && await f.validate()) {
    try {
      saving.value = true

      if (sa.id != -1) {
        shippingAddress.value = await ShippingAddressService.updateShippingAddress(sa.id, {
          street: sa.street,
          number: sa.number,
          complement: sa.complement,
          district: sa.district,
          city: sa.city,
          state: sa.state,
          postalCode: sa.postalCode
        })
      } else {
        const customer = await CustomerService.findSelfCustomer()

        shippingAddress.value = await ShippingAddressService.createShippingAddress({
          customerId: customer.id,
          street: sa.street,
          number: sa.number,
          complement: sa.complement,
          district: sa.district,
          city: sa.city,
          state: sa.state,
          postalCode: sa.postalCode
        })
      }

      notifications.info("Endereço de entrega salvo com sucesso!")

      if (returnTo.value) {
        router.push(returnTo.value)
      }
    } catch (e) {
      captureException(new Error('Failed to save shipping addres', { cause: e }))

      notifications.error("Houve um problema ao salvar seu endereço. Por favor, tente novamente mais tarde")
    } finally {
      saving.value = false
    }
  }
}

const loadQueryParameters = () => {
  const returnToParam = route.query["returnTo"]
  if (typeof returnToParam === "string") {
    returnTo.value = returnToParam
  }
}

const { data, error } = await useAsyncData(() => {
  return Promise.all([
    CustomerService.findSelfPrimarySubscription()
      .catch((error: FetchError) => {
        if (error.status == 404) {
          return null
        } else {
          throw error
        }
      }),
    CustomerService.findSelfShippingAddresses()
      .catch((error: FetchError) => {
        if (error.status == 404) {
          return null
        } else {
          throw error
        }
      }),
  ])
})

reportOnError(error, 'Failed to load shipping address')

primarySubscription.value = data.value?.[0] as PrimarySubscription | null
shippingAddress.value = (data.value?.[1] as ShippingAddress[] | null)?.find((sa) => sa.id == primarySubscription.value?.shippingAddressId)
  ?? (data.value?.[1] as ShippingAddress[] | null)?.[0]
  ?? {
  tenant: "magenta",
  id: -1,
  street: "",
  number: "",
  complement: "",
  district: "",
  city: "",
  state: "",
  postalCode: "",
  createdAt: "",
  updatedAt: ""
}

if (shippingAddress.value) {
  initialPostalCode.value = shippingAddress.value.postalCode

  await calculateShippingRate(shippingAddress.value)
}

loadQueryParameters()

const centsToString = (cents: number): string =>
  (cents / 100.0).toFixed(2).replace(/\./g, ',')
</script>
<style lang="sass" scoped>
.shipping-address
  position: relative
  max-width: 708px
  margin: 0 auto
  padding: 0 14px
  text-align: left
  animation: fade-in .2s forwards

  .shipping-rate-notice
    font-size: 12px
    font-style: italic
    margin-bottom: 16px

  .shipping

    table
      width: 100%
      font-weight: bold

      td:first-child
        text-align: left

      td:last-child
        text-align: right

        .loading
          transform: scale(0.5)

  .actions
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    gap: 16px
    margin-top: 32px

.edit-shipping-address-loading
  display: flex
  height: 320px
  justify-content: center
  align-items: center
</style>
